import { css } from "@emotion/react";

// NOTE: STOP USING THESE BREAKPOINTS PLEASE!
// TODO: Remove these endpoints once all of them are migrated to the new version
const BreakpointsOld = {
  xsmall: 350,
  small: 768,
  medium: 1024,
  large: 1280,
  xlarge: 1400,
};

// These breakpoints match the default ones provided by Material UI
const BreakpointsNew = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
};

const Breakpoints = { ...BreakpointsOld, ...BreakpointsNew };

export const maxWidth = Object.keys(Breakpoints).reduce((acc: any, key) => {
  acc[key] = (literals: TemplateStringsArray, ...args: any[]) => css`
    @media (max-width: ${Breakpoints[key as keyof typeof Breakpoints] - 1}px) {
      ${css(literals, ...args)};
    }
  `;

  return acc;
}, {});

export const minWidth = Object.keys(Breakpoints).reduce((acc: any, key) => {
  acc[key] = (literals: TemplateStringsArray, ...args: any[]) => css`
    @media (min-width: ${Breakpoints[key as keyof typeof Breakpoints]}px) {
      ${css(literals, ...args)};
    }
  `;

  return acc;
}, {});
