/**
 * WARNING: Be aware when you use this, do not spread the use of it everywhere. It is still under development, keep using colors from kinetic.
 * NOTES:
 * 1. Check if you pair already exist before adding a new one.
 * 2. Include just pure colors. Colors with transparency should be handle in every styled component that needs it through "polished" library.
 * 3. Use numeric keys
 * 4. If you add a new color here, the same key must be created in the opposite theme:
 *    - If there is no color defined in the opposite theme you can reuse the same or ask UX designer.
 * 5. Keep in mind whether the component you are building has to switch along with theme or not.
 *    In the second case you might want to not use these color but fixed ones.
 * TODO: Evaluate to create "images" key for SVGs if need it.
 */
export default {
  background: {
    1: "#FFFFFF",
    2: "#FFFFFF",
    3: "#FAFAF7",
    4: "#FFFFFF",
    5: "#252524",
  },
  border: {
    1: "#E4E4E4",
    2: "#FFFFFF",
  },
  typography: {
    1: "#FFFFFF",
    2: "#494947",
    3: "#494947",
    4: "#959592",
    5: "#313130",
    6: "#555553",
  },
  action: {
    1: "#134EE7",
    2: "#959592",
  },
};
