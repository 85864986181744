import { css } from "@emotion/react";

export const Elevation = {
  1: css`
    filter: drop-shadow(0px 1px 0px #d3d3d3);
  `,
  2: css`
    filter: drop-shadow(0px 2px 4px rgba(32, 32, 32, 0.25));
  `,
  3: css`
    filter: drop-shadow(0px 2px 8px rgba(32, 32, 32, 0.25));
  `,
  4: css`
    filter: drop-shadow(0px 2px 16px rgba(32, 32, 32, 0.25));
  `,
  5: css`
    filter: drop-shadow(0px 8px 24px rgba(32, 32, 32, 0.25));
  `,
} as const;

export type ElevationType = keyof typeof Elevation;
