import { BorderRadius as borderRadius } from "./border-radius";
import type { BorderRadiusType } from "./border-radius";
import { IconSizes as iconSizes } from "./icon-sizes";
import type { IconSizeType } from "./icon-sizes";
import { Elevation as elevation } from "./elevation";
import type { ElevationType } from "./elevation";
import { Paddings as paddings } from "./paddings";
import type { PaddingType } from "./paddings";
import * as palette from "./palette";

import * as v2 from "./v2";
import * as v3 from "./v3";

export { v2, v3, borderRadius, elevation, iconSizes, paddings, palette };
export type { BorderRadiusType, ElevationType, IconSizeType, PaddingType };
