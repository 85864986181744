export const IconSizes = {
  small: {
    width: "15px",
    height: "15px",
  },
  medium: {
    width: "20px",
    height: "20px",
  },
  large: {
    width: "25px",
    height: "25px",
  },
} as const;

export type IconSizeType = keyof typeof IconSizes;
