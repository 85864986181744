export default {
  gray: {
    gray1: "#8F8F8F",
  },
  blue: {
    blue1: "#F3F7FD",
    blue2: "#0C5CD4",
  },
  white: "#FFFFFF",
};
