export const Paddings = {
  2: "2px",
  4: "4px",
  8: "8px",
  10: "10px",
  12: "12px",
  14: "14px",
  16: "16px",
  20: "20px",
  24: "24px",
  32: "32px",
  48: "48px",
  64: "64px",
  80: "80px",
  96: "96px",
} as const;

export type PaddingType = keyof typeof Paddings;
