import * as breakpoint from "./breakpoint";

// TODO: Remove these colors and start using light and dark ones
import * as kinetic from "./kinetic";
import * as radix from "./radix";

import { default as colorsLight } from "./colors/light";
import { default as colorsDark } from "./colors/dark";

export const theme = {
  breakpoint,
  kinetic,
  radix,
  colors: colorsLight,
};
export const themeDark = {
  breakpoint,
  kinetic,
  radix,
  colors: colorsDark,
};

export type Theme = typeof theme;
