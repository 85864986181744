import C from "chroma-js";

// FIXME: Shoudn't this be reverse? 900 is darker 100 is lighter
const neutralshade = {
  100: C("#181818"),
  200: C("#252524"),
  300: C("#313130"),
  400: C("#3D3D3C"),
  500: C("#494947"),
  600: C("#555553"),
  700: C("#62625F"),
  800: C("#6E6E6B"),
  900: C("#7A7A77"),
};

// FIXME: 700 doesn't fit?
const neutraltint = {
  100: C("#FAFAF7"),
  200: C("#F2F2F1"),
  300: C("#E4E4E4"),
  400: C("#D7D7D6"),
  500: C("#CACAC9"),
  600: C("#BDBDBB"),
  700: C("#AFAFAF"),
  800: C("#A2A2A0"),
  900: C("#959592"),
};

const primaryshade = {
  100: C("#01040D"),
  200: C("#020817"),
  300: C("#04102E"),
  400: C("#061745"),
  500: C("#081F5C"),
  600: C("#0A2774"),
  700: C("#0B2F8B"),
  800: C("#0D37A2"),
  900: C("#0F3EB9"),
};

const primarytint = {
  100: C("#F4F7FF"),
  200: C("#E7EDFD"),
  300: C("#D0DCFA"),
  400: C("#B8CAF8"),
  500: C("#A1B8F5"),
  600: C("#89A7F3"),
  700: C("#7195F1"),
  800: C("#5A83EE"),
  900: C("#4271EC"),
};

// FIXME: Shoudn't this be reverse? 900 is darker
const secondaryshade = {
  100: C("#010D10"),
  200: C("#021418"),
  300: C("#052730"),
  400: C("#073B48"),
  500: C("#0A4F60"),
  600: C("#0D6378"),
  700: C("#0F7690"),
  800: C("#128AA8"),
  900: C("#149EC0"),
};

const secondarytint = {
  100: C("#F4FDFF"),
  200: C("#E8F9FE"),
  300: C("#D1F3FC"),
  400: C("#BAEEFB"),
  500: C("#A3E8F9"),
  600: C("#8CE2F8"),
  700: C("#75DCF6"),
  800: C("#5ED6F5"),
  900: C("#47D1F3"),
};

// FIXME: Shoudn't this be reverse? 900 is darker
const urgencyshade = {
  300: C("#300C07"),
  600: C("#781E12"),
  900: C("#F47766"),
};

const urgencytint = {
  300: C("#FDEcE9"),
  600: C("#F9B1A7"),
  900: C("#F47766"),
};

// FIXME: Shoudn't this be reverse? 900 is darker
const positiveshade = {
  300: C("#062301"),
  600: C("#0D4703"),
  900: C("#167C05"),
};

const positivetint = {
  300: C("#E9F7E6"),
  600: C("#BCE8B5"),
  900: C("#63C851"),
};

const warningshade = {
  300: C("#332903"),
  600: C("#665206"),
  900: C("#B3900A"),
};

const black = C("#000000");
const white = C("#FFFFFF");
const primarybaseline = C("#134EE7");
const secondarybaseline = C("#19C5F0");
const positivebaseline = C("#20B107");
const urgencybaseline = C("#EF3C24");
const warningbaseline = C("#FFCE0E");

const warningtint = {
  300: C("#FFFAE7"),
  600: C("#FFEB9F"),
  900: C("#FFDD56"),
};

export const chroma = {
  neutralshade,
  neutraltint,
  primaryshade,
  primarytint,
  secondaryshade,
  secondarytint,
  urgencyshade,
  urgencytint,
  positiveshade,
  positivetint,
  warningshade,
  warningtint,
  black,
  white,
  primarybaseline,
  secondarybaseline,
  positivebaseline,
  urgencybaseline,
  warningbaseline,
};

// export const rgb: RGBMap<typeof chroma> = map(invoker(0, 'css'), chroma)

// export const rgba: Record<keyof typeof chroma, (alpha: number) => string> = map(
//   (c: C.Color) => {
//     return (alpha: number) => c.alpha(alpha).css()
//   },
//   chroma
// )
