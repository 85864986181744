import C from "chroma-js";
import { map, invoker } from "ramda";

type RGBMap<T> = { [K in keyof T]: string };

export const chroma = {
  blue100: C("#EEF4FF"),
  blue200: C("#B7D1F9"),
  blue300: C("#6FA4F4"),
  primaryBlue: C("#0F67EC"),
  blue700: C("#0C52BD"),
  blue800: C("#093E8E"),
  blue900: C("#06295E"),
  fluoro100: C("#EBFCFC"),
  fluoro200: C("#C2F5F5"),
  fluoro300: C("#85EBEB"),
  primaryFluoro: C("#33DEDE"),
  fluoro700: C("#1BA4A4"),
  fluoro800: C("#137575"),
  fluoro900: C("#0B4646"),

  white: C("#FFFFFF"),
  gray100: C("#f3f3f3"),
  gray200: C("#E7E7E7"),
  gray300: C("#d2d2d2"),
  gray400: C("#c2c2c2"),
  gray700: C("#707070"),
  gray800: C("#474747"),
  gray900: C("#202020"),
  warning100: C("#FDF9E7"),
  warning500: C("#E9CD7B"),
  warning800: C("#B99A0E"),
  warning900: C("#8C6E17"),
  negative100: C("#FAEBEB"),
  negative500: C("#DF8988"),
  negative800: C("#CA3A38"),
  negative900: C("#A32D2B"),
  positive100: C("#EAF6EE"),
  positive500: C("#82CA96"),
  positive800: C("#2EA650"),
  positive900: C("#207438"),
};

export const rgb: RGBMap<typeof chroma> = map(invoker(0, "css"), chroma);

export const rgba: Record<keyof typeof chroma, (alpha: number) => string> = map(
  (c: C.Color) => {
    return (alpha: number) => c.alpha(alpha).css();
  },
  chroma
);
