import C from "chroma-js";
import { map, invoker } from "ramda";

type RGBMap<T> = { [K in keyof T]: string };

const gray = {
  grayTextDarker: C("#101011"),
  grayTextDark: C("#202122"),
  grayTextLight: C("#404244"),
  grayTextLighter: C("#606366"),
  grayUiDarker: C("#A0A6AA"),
  grayUiDark: C("#C1C6C9"),
  grayUiLight: C("#E1E5E6"),
  grayUiLighter: C("#F1F3F3"),
  grayUiLighter2: C("#FAFAF7"),
  white: C("#FFFFFF"),
};
const blue = {
  blueDarker: C("#052555"),
  blueDark: C("#084094"),
  blue: C("#0C5CD4"),
  blueLight: C("#86AEEA"),
  blueBg: C("#E7EFFB"),
};
const fluoro = {
  fluoroDarker: C("#0C3F4C"),
  fluoroDark: C("#156F85"),
  fluoro: C("#1E9EBE"),
  fluoroLight: C("#8FCFDF"),
  fluoroBg: C("#E9F5F9"),
};
const positive = {
  positiveDarker: C("#256418"),
  positiveDark: C("#318520"),
  positive: C("#3DA628"),
  positiveBg: C("#ECF6EA"),
};
const negative = {
  negativeDarker: C("#7A1F1E"),
  negativeDark: C("#AA3322"),
  negative: C("#CC3432"),
  negativeBg: C("#FAEBEB"),
};
const warning = {
  warningDarker: C("#7E6503"),
  warningDark: C("#C9A105"),
  warning: C("#F9C809"),
  warningBg: C("#FEFAE6"),
};

export const chroma = {
  ...gray,
  ...blue,
  ...fluoro,
  ...positive,
  ...negative,
  ...warning,
};

export const rgb: RGBMap<typeof chroma> = map(invoker(0, "css"), chroma);

export const rgba: Record<keyof typeof chroma, (alpha: number) => string> = map(
  (c: C.Color) => {
    return (alpha: number) => c.alpha(alpha).css();
  },
  chroma
);
